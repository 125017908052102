/* eslint-disable no-unused-vars */
'use strict'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { closeModalPopup, hideScroll } from '../../../utils/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './ChartSettings.module.scss'
import PrimaryButton from '../../Lib/Button/PrimaryButton'
import SecondryButton from '../../Lib/Button/SecondryButton'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import ChartsMobileHeader from '../../Lib/ChartsMobileHeader/ChartsMobileHeader'
import TimeRange from '../ChartsTimeMobile/TimeRange/TimeRange'
import TimeMeasurement from '../ChartsTimeMobile/TimeMeasurement/TimeMeasurement'
import ChartEventsMobile from '../ChartEventsMobile/ChartEventsMobile'
import ChartStylesMobile from '../ChartStylesMobile/ChartStylesMobile'
import timePeriods from '../../../data/ChartTimePeriods.json'
import Indicator from '../Indicator/Indicator'
import ChartComparison from '../ChartComparison/ChartComparison'
import { CHART_EVENTS, CHART_STYLES, URLS } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import { getPeriod } from '../../../utils/formatter'

const ChartSettings = ({ chart, symbol, xid, numberOfDays, isAdvance, addSymbolComparison, resetChart }) => {
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [timeRange, setTimeRange] = useState({showTimeRange: false, timeRangeSelection: '1 Year'})
  const [timeMeasurement, setTimeMeasurement] = useState({showTimeMeasurement: false, timeMeasurementSelection: 'Daily'})
  const [indicator, setIndicator] = useState({showIndicator: false, indicatorSelection: [], isReset: ''})
  const [chartComparison, setChartComparison] = useState({ showChartComparison: false, chartComparisonSelection: [] })
  const [showChartEvent, setShowChartEvent] = useState(false)
  const [chartEvents, setChartEvents] = useState([...CHART_EVENTS])
  const [selectedChartEvents, setSelectedChartEvents] = useState([])
  const [showChartStyle, setShowChartStyle] = useState(false)
  const [selectedChartStyles, setSelectedChartStyles] = useState({})
  const [chartStyles, setChartStyles] = useState([...CHART_STYLES])
  const [days] = useState(numberOfDays)

  const [chartSettings, setchartSettings] = useState({
    INDICATORS: [],
    panelInputs: [],
    days: {},
    dataInterval: {},
    dataPeriod: {},
    chartType: '',
    selectedSymbol: []
  })

  const closePopup = (e) => {
    if (closeModalPopup(e)) {
      hideScroll(!isModelOpen)
      setIsModelOpen(!isModelOpen)
    }
  }
  const ResetSettings = (e) => {
    e && e.stopPropagation()
    setchartSettings({
      INDICATORS: [],
      panelInputs: [],
      days: {},
      dataInterval: {},
      dataPeriod: {},
      chartType: '',
      selectedSymbol: []
    })
    setTimeRange({showTimeRange: false, timeRangeSelection: '1 Year'})
    setTimeMeasurement({showTimeMeasurement: false, timeMeasurementSelection: 'Daily'})
    setIndicator({showIndicator: false, indicatorSelection: [], isReset: new Date().toString()})
    setChartComparison({ showChartComparison: false })
    setChartEvents(chartEvents.map(event => {
      event.checked = false
      return event
    }))
    setSelectedChartEvents([])
    setChartStyles(chartStyles.map(style => {
      style.checked = false
      return style
    }))
    setSelectedChartStyles({})
    resetChart()
  }

  const closeTimeRangeHandler = (lastSelectedValue) => {
    if (lastSelectedValue === '') {
      setTimeRange({ showTimeRange: false, timeRangeSelection: timeRange.timeRangeSelection })
    } else {
      if (lastSelectedValue === timeRange.timeRangeSelection) {
        setTimeRange({ showTimeRange: false, timeRangeSelection: timeRange.timeRangeSelection })
      } else {
        const timeMeasurementData = timePeriods.filter(x => x.text === lastSelectedValue)[0]
        setTimeRange({ showTimeRange: false, timeRangeSelection: lastSelectedValue })
        setTimeMeasurement({ showTimeMeasurement: false, timeMeasurementSelection: timeMeasurementData.value[0].text })
      }
    }
  }

  const closeTimeMeasurementHandler = (lastSelectedValue) => {
    if (lastSelectedValue === '') {
      setTimeMeasurement({ showTimeMeasurement: false, timeMeasurementSelection: timeMeasurement.timeMeasurementSelection })
    } else {
      setTimeMeasurement({ showTimeMeasurement: false, timeMeasurementSelection: lastSelectedValue })
    }
  }
  const closeIndicatorHandler = (data) => {
    setIndicator({showIndicator: false, indicatorSelection: data.INDICATORS})
  }

  const closeChartComparisonHandler = (selectedSymbols) => {
    if (selectedSymbols) {
      setChartComparison({ showChartComparison: false, chartComparisonSelection: selectedSymbols })
      addSymbolComparison(selectedSymbols)
    } else {
      setChartComparison({ showChartComparison: false })
    }
  }

  const loadChartSettings = e => {
    if (!e) return
    e.preventDefault()
    hideScroll(!isModelOpen)
    setIsModelOpen(!isModelOpen)
  }
  function handleApply (e) {
    e && e.stopPropagation()

    const data = {
      INDICATORS: indicator.indicatorSelection,
      days: timePeriods.filter(x => x.text === timeRange.timeRangeSelection)[0].value[0].value.days,
      dataInterval: timePeriods.filter(x => x.text === timeRange.timeRangeSelection)[0].value[0].value.dataInterval,
      dataPeriod: timePeriods.filter(x => x.text === timeRange.timeRangeSelection)[0].value[0].value.dataPeriod,
      selectedSymbol: chartComparison.chartComparisonSelection,
      chartType: selectedChartStyles.value || 'line',
      panelInputs: selectedChartEvents
    }
    setchartSettings({
      ...chartSettings,
      ...data
    })
    setIsModelOpen(!isModelOpen)
    hideScroll(!isModelOpen)

    selectedChartEvents.forEach(event => {
      let id = event.toLowerCase()
      if (id === 'customearnings') {
        xid && addCustomEarningsEvents()
      } else {
        chart.panels[0].addEvent(id)
      }
    })
    chart.setDays(data.days)
    chart.setDataPeriod(data.dataPeriod)
    chart.setDataInterval(data.dataInterval)

    indicator.indicatorSelection.forEach(indicatorItem => {
      const existingIndicator = chart.getIndicatorsById(indicatorItem.Type)
      if (existingIndicator.length === 0) {
        if (indicatorItem.panel === 'lower') {
          const newPanel = chart.addPanel()
          addIndicator(newPanel, indicatorItem)
        } else {
          addIndicator(chart.panels[0], indicatorItem)
        }
      }
    })

    chart.setPriceMarkerType(data.chartType)
    if (data.chartType === 'fill') {
      const priceIndicator = chart.panels[0].indicators[0]
      if (priceIndicator) {
        priceIndicator.setStyle('fillColor', 'rgba(0,68,128, 0.25)')
        priceIndicator.setStyle('fillColorStop', 'rgba(0,68,128, 0.25)')
      }
    }
    chart.loadData()
  }

  const mapCustomEarningEvents = (data) => {
    return data.map(event => {
      return {
        ...event,
        date: event.periodEndDate
      }
    })
  }

  const addCustomEarningsEvents = () => {
    function setEarningData (response) {
      if (!response.isError && response.isDataRequestComplete) {
        chart.panels[0].addEvent('custom', {
          name: 'Earning Event',
          dataset: mapCustomEarningEvents(response.data.historical)
        })
      }
    }
    DataRequest.execute(
      `${URLS.PLATFORM.EQUITIES_MORNINGSTAR_ESTIMATES}/trends/${xid}`,
      {
        params: {
          periodicity: 'quarterly',
          numberOfEstimatePeriods: 1,
          includeHistory: true,
          numberOfHistoricalPeriods: getPeriod(days, true)
        }
      },
      setEarningData,
      null
    )
  }

  function addIndicator (panel, indicator) {
    const addedIndicator = panel.addIndicator(indicator.Type)
    for (let [key, value] of Object.entries(indicator.Params)) {
      addedIndicator.setInput(key, value)
    }
    if (indicator.style) {
      for (let [key, value] of Object.entries(indicator.style)) {
        addedIndicator.setStyle(key, value)
      }
    }
  }

  const showTimeRangeModal = () => {
    setTimeRange({ showTimeRange: true, timeRangeSelection: timeRange.timeRangeSelection })
  }

  const showTimeMeasurementModal = () => {
    setTimeMeasurement({ showTimeMeasurement: true, timeMeasurementSelection: timeMeasurement.timeMeasurementSelection })
  }

  const showChartComparisonModal = () => {
    setChartComparison({ showChartComparison: true, chartComparisonSelection: chartComparison.chartComparisonSelection })
  }
  const showIndicatorModal = () => {
    setIndicator({showIndicator: true, indicatorSelection: []})
  }

  const getSelectedEvents = (data) => {
    setSelectedChartEvents(data)
    setShowChartEvent(false)
  }

  const getSelectedStyle = (data) => {
    setSelectedChartStyles(data)
    setShowChartStyle(false)
  }

  const popOverBody = (<div className={styles.settingsContainer}>

    <div className={styles.settingsContent}>
      <div className={styles.settingsIPadContainer}>
        <div className={styles.settingsIPadDivLeft}>

          <div className={styles.settingsDataDiv} role='button' tabIndex={0} onKeyDown={showIndicatorModal} onClick={showIndicatorModal}>
            <div className={styles.settingsCol1}>Indicators</div>
            <button data-testid='chart-setting-indicator' className={styles.settingsCol2}>
              <FontAwesomeIcon icon={['far', 'chevron-right']} className={styles.fontIcon} />
            </button>
          </div>

          <div className={styles.settingsDataDiv} role='button' tabIndex={0} onKeyDown={() => { setShowChartEvent(true) }} onClick={() => { setShowChartEvent(true) }}>
            <div className={styles.settingsCol1}>Events</div>
            <button data-testid='chart-setting-events' className={styles.settingsCol2}>
              {selectedChartEvents.length > 0 && `${selectedChartEvents.length} Selected `}
              <FontAwesomeIcon icon={['far', 'chevron-right']} className={styles.fontIcon} />
            </button>
          </div>

          <div className={styles.settingsDataDiv} role='button' tabIndex={0} onKeyDown={showTimeRangeModal} onClick={showTimeRangeModal}>
            <div className={styles.settingsCol1}>Time Range</div>
            <button data-testid='chart-setting-time-range' className={styles.settingsCol2}>
              {timeRange.timeRangeSelection }
              <FontAwesomeIcon icon={['far', 'chevron-right']} className={styles.fontIcon} />
            </button>
          </div>

          <div className={styles.settingsDataDiv} role='button' tabIndex={0} onKeyDown={showTimeMeasurementModal} onClick={showTimeMeasurementModal}>
            <div className={styles.settingsCol1}>Time Measurement</div>
            <button data-testid='chart-setting-time-measurement' className={styles.settingsCol2}>
              {timeMeasurement.timeMeasurementSelection }
              <FontAwesomeIcon icon={['far', 'chevron-right']} className={styles.fontIcon} />
            </button>
          </div>

          <div className={styles.settingsDataDiv} role='button' tabIndex={0} onKeyDown={() => { setShowChartStyle(true) }} onClick={() => { setShowChartStyle(true) }}>
            <div className={styles.settingsCol1}>Style</div>
            <button data-testid='chart-setting-style' className={styles.settingsCol2} >
              {selectedChartStyles && selectedChartStyles.Text }
              <FontAwesomeIcon icon={['far', 'chevron-right']} className={styles.fontIcon} />
            </button>
          </div>

          <div className={styles.settingsDataDiv} role='button' tabIndex={0} onKeyDown={showChartComparisonModal} onClick={showChartComparisonModal}>
            <div className={styles.settingsCol1}>Comparison</div>
            <button data-testid='chart-setting-comparison' className={styles.settingsCol2}>
              <FontAwesomeIcon icon={['fal', 'plus']} />
            </button>
          </div>
        </div>
        <div className={styles['button-container']}>
          <PrimaryButton clickHandler={handleApply} buttonText='Apply' dataTestId='chart-setting-apply' />
          <SecondryButton clickHandler={ResetSettings} buttonText='Reset' dataTestId='chart-setting-reset' />
        </div>
      </div>
    </div>
  </div>
  )
  return (
    <div className={styles.chartSettingsContainer}>
      <div className={styles.chartSettingMain}>
        <button
          className={styles.chartSettingGearIcon}
          data-testid={`button_chartSettings`}
          type='button'
          onClick={loadChartSettings}
          onKeyPress={loadChartSettings}
          tabIndex='0'>
          <FontAwesomeIcon icon={['fas', 'cog']} color='#0572D6' className={styles.fontIcon} />
          <span className={styles.chartSettingLabel}>Chart Settings</span>
        </button>

        {isModelOpen &&
        <ModalPopUp customHeader >
          <ChartsMobileHeader headerText='Chart Settings' closeHandler={closePopup} headerDataTestId='ChartsSettingsCloseTestId' />
          {popOverBody}
        </ModalPopUp>
        }
      </div>
      {timeRange.showTimeRange && <TimeRange closeTimeRange={closeTimeRangeHandler} activeTimeRange={timeRange.timeRangeSelection} />}
      {timeMeasurement.showTimeMeasurement && <TimeMeasurement closeTimeMeasurement={closeTimeMeasurementHandler} activeTimeRange={timeRange.timeRangeSelection} activeTimeMeasurement={timeMeasurement.timeMeasurementSelection} />}
      {indicator.showIndicator && <Indicator closeIndicator={closeIndicatorHandler} isMobile isAdvance={isAdvance} chart={chart} toggleArrow={() => {}} />}
      {chartComparison.showChartComparison && <ChartComparison symbolsList={[]} symbol={symbol} venueXid={xid} closeChartComparisonHandler={closeChartComparisonHandler} />}
      {showChartEvent &&
        <ChartEventsMobile eventsList={[...chartEvents]} getSelectedEvents={getSelectedEvents} onClose={() => { setShowChartEvent(false) }} resetHandler={() => {}} />
      }
      {showChartStyle &&
        <ChartStylesMobile stylesList={chartStyles} getSelectedStyle={getSelectedStyle} onClose={() => { setShowChartStyle(false) }} />
      }

    </div>
  )
}

ChartSettings.propTypes = {
  chart: PropTypes.object,
  symbol: PropTypes.string,
  xid: PropTypes.number,
  numberOfDays: PropTypes.number,
  isAdvance: PropTypes.bool,
  addSymbolComparison: PropTypes.func,
  resetChart: PropTypes.func
}

export default ChartSettings
