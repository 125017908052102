import React, {useState} from 'react'
import PropTypes from 'prop-types'
import CheckBox from '../../Lib/CheckBox/CheckBox'
import PrimaryButton from '../../Lib/Button/PrimaryButton'
import SecondryButton from '../../Lib/Button/SecondryButton'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import ChartsMobileHeader from '../../Lib/ChartsMobileHeader/ChartsMobileHeader'
import styles from './ChartEventsMobile.module.scss'

export default function ChartEventsMobile ({getSelectedEvents, onClose, eventsList}) {
  const [events, SetEvents] = useState([...eventsList])
  const checkUncheck = (name, isChecked) => {
    const updatedEvents = events.map(event => {
      if (event.text === name) {
        event.checked = isChecked
      }
      return event
    })
    SetEvents([...updatedEvents])
  }
  const onChange = (e) => {
    const name = e.target.name
    const isChecked = e.target.checked
    checkUncheck(name, isChecked)
  }
  const onApply = (e) => {
    const selectedEvents = []
    events.map(event => {
      if (event.checked === true) {
        selectedEvents.push(event.value)
      }
    })
    getSelectedEvents(selectedEvents)
  }
  const onReset = (e) => {
    const resetEvents = events.map(event => {
      event.checked = false
      return event
    })
    SetEvents([...resetEvents])
  }

  return (
    <ModalPopUp customHeader customBody>
      <ChartsMobileHeader headerText='Events' closeHandler={onClose} headerDataTestId='charteventsClose' />
      <div className={styles.chartEventsMobile}>
        <div className={styles.eventOptions}>
          {events.map((event, key) => {
            return <CheckBox key={key} label={event.text} isChecked={event.checked} onCheck={onChange} />
          })}
        </div>
        <div className={styles.footer}>
          <PrimaryButton tabIndex='0' buttonText='Apply' clickHandler={onApply} dataTestId='chartEventApplyButton' />
          <SecondryButton tabIndex='0' buttonText='Reset' clickHandler={onReset} dataTestId='chartEventResetButton' />
        </div>
      </div>
    </ModalPopUp>
  )
}

ChartEventsMobile.propTypes = {
  eventsList: PropTypes.array,
  getSelectedEvents: PropTypes.func,
  onClose: PropTypes.func
}
